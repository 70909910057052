<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="row justify-content-md-center">
      <div class="col-12">
        <div class="card card-fullheight">
          <div class="card-body hello-box">
            <div class="table-responsive">
              <p>{{$t('contact.directors.description')}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <div id="contact-directors" class="row">
                <div class="col-md-6">
                  <label style="color:#fff">{{$t('contact.directors.subject')}}</label>
                  <label class="label-select">{{$t('contact.directors.subject')}}</label>
                  <v-select :clearable="false" :searchable="false" v-model="form.subject"  :options="subjectList"  >
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.label }}
                      </div>
                    </template>
                    <template slot="selected-option">
                      <div class="selected d-center">
                        {{ form.subject }}
                      </div>
                    </template>
                  </v-select>
                </div>
                <div class="md-form col-md-6">
                  <div class="">
                    <label>{{$t('contact.directors.contactForm')}}</label>
                    <custom-input
                      v-model="form.contactForm"
                      name="contactForm"
                      type="text"
                      v-validate="{ required: false }"
                      :error="errors.first('contactForm')">
                    </custom-input>
                  </div>
                </div>
                <div class="md-form col-md-12">
                  <custom-textarea
                    v-model="form.message"
                    name="message"
                    :label="$t('contact.directors.textareaSubject')"
                    type="text"
                    :rows="5"
                    v-validate="{ required: false }"
                    :error="errors.first('form.message')"
                    background="#fff">
                  </custom-textarea>
                </div>
                <div class="dropfile md-form col-md-12">
                  <label for="files" class="input-dropfile dropzone border-info text-center">
                    <i class="ti-import text-info font-40" style="margin: auto;"></i>
                    <h5 style="margin-top:10px;" ref="labelfiles">{{$t('dropfile')}}</h5>
                    <span ref="spanfiles"></span>
                  </label>
                  <input id="files" type="file" name="file" ref="files" style="display: none;" v-on:change="sizevalidation()"/>
                </div>
                <div class="py-1 col-md-12 mt-5">
                  <div class="mb-12">
                    <button class="btn btn btn-outline-light pull-right" v-on:click="sendContact()">{{ $t('actions.send_now') }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card card-fullheight">
          <div class="card-body">
            <div class="table-responsive">
              <div class="row">
              <div class="col-md-12">
                <custom-table ref="contatos" :gridCols="tableContactFieldsHeader" :gridData="contacts" :showHeader="true"
                  :showActionColumn="true" :totalPages="totalPages" :currentPage="page" :itemsPerPage="contactPerPage"
                  @onPageChanged="onPageChanged" :isLoading="isPageLoading">
                  <template slot="ContentAction" slot-scope="row">
                    <RatingStar :ratingValue="row.item.rating" :idElement="row.item.id_contact_directors"/>
                  </template>
                </custom-table>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Services
import ContactService from '@/services/ContactService'

// Form
import CustomInput from '@/components/Forms/CustomInput.vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import CustomTextarea from '@/components/Forms/CustomTextarea.vue'
import RatingStar from '@/components/Forms/RatingStar.vue'

// Table
import CustomTable from '@/components/Table/CustomTable.vue'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'ContactDirectors',
  metaInfo: {
    titleTemplate: 'Contato - Diretores'
  },
  data () {
    return {
      subjectList: [ 'Elogio', 'Dúvida', 'Sugestão', 'Reclamação', 'Outros' ],
      form: {
        message: '',
        contactForm: '',
        subject: '',
        filename: '',
        file: ''
      },
      contacts: [],
      filter: {},
      tableContactFieldsHeader: [
        { field: 'message', display: 'Mensagens', size: '85%' }
      ],
      page: 1,
      totalPages: 0,
      contactPerPage: 20,
      isContactLoading: true,
      isLoading: false,
      fullPage: true,
      isPageLoading: true
    }
  },
  components: {
    vSelect,
    CustomInput,
    CustomTextarea,
    RatingStar,
    CustomTable,
    Loading
  },
  mounted () {
    this.showContacts(this.filters)
  },
  methods: {
    showContacts (filters) {
      this.isContactLoading = true
      ContactService.getContactsDirectors(this.page, this.contactPerPage).then(contacts => {
        this.contacts = contacts.data.data
        this.totalPages = contacts.data.pagination.total_pages
        this.isContactLoading = false
        this.isLoading = false
        this.isPageLoading = false
      })
    },
    sendContact () {
      let me = this
      let formData = new FormData()
      formData.append('message', this.form.message)
      formData.append('contactform', this.form.contactForm)
      formData.append('subject', this.form.subject)
      formData.append('rating', 0)
      formData.append('filename', this.form.filename)
      formData.append('file', this.form.file)
      me.isLoading = true
      ContactService.sendContactDirectors(formData).then(dataJson => {
        me.isLoading = false
        me.onClearImage()
        me.form.message = ''
        me.form.contactForm = ''
        me.form.subject = ''
      }).then(function (res) {
        let msg = `<h4>Sua mensagem foi enviada com sucesso!</h4>
        <h4>Em breve entraremos em contato.</h4>
        <div class="imgs-directors" style="margin: 20px 10px 10px">
          <img src="/static/images/Carlos.png" style="width: 90px">
          <img src="/static/images/Rafael.png" style="width: 90px; margin: 0 25px">
          <img src="/static/images/Arlon.png" style="width: 90px">
        </div>`
        global.instanceApp.$alertSwal.modalAlert(null, msg, 'success', '').then((result) => {})
        me.isLoading = false
        me.isContactLoading = false
        me.isPageLoading = false
        me.showContacts(me.filters)
        me.$refs['contatos'].$forceUpdate()
      }).catch(() => {
      })
    },
    onPageChanged (page) {
      this.page = page
      this.isPageLoading = true
      this.showContacts(this.filter)
    },
    sizevalidation () {
      this.form.filename = this.$refs.files.files[0].name
      this.form.file = this.$refs.files.files[0]
      this.$refs.labelfiles.innerText = `Arquivo ${this.form.filename} adicionado com sucesso.`
      this.$refs.spanfiles.innerText = `click aqui para trocar de arquivo.`
      if (this.$refs.files.files[0].size > 10240000) {
        alert('Arquivo grande demais!')
        this.$refs.labelfiles.innerText = 'Clique aqui para adicionar um arquivo.'
        this.$refs.spanfiles.innerText = ``
        this.$refs.files.value = null
        this.form.filename = ''
      }
    },
    onClearImage () {
      this.$refs.labelfiles.innerText = 'Clique aqui para adicionar um arquivo.'
      this.$refs.spanfiles.innerText = ``
      this.$refs.files.value = null
    }
  }
}
</script>

<style scoped lang="scss">
  .v-select{
    margin-top: 1rem;
  }
  .dropfile{
    height: 125px;
    box-sizing: border-box;
  }
  .input-dropfile{
    width: 100%;
    pointer-events: auto;
    cursor: pointer;
    padding: 20px 0;
    height: 125px;
    box-sizing: border-box;
    position: relative !important;
  }
  #files{
    box-sizing: border-box;
  }
  .hello-box p{
    padding-top: 1rem;
  }
  .imgs-directors{
    margin: 10px 0;
  }
  .imgs-directors img{
    width: 90px;
  }
  .label-select{
    position: absolute;
    top: 15px;
    left: 15px;
  }
</style>
